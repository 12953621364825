<template>
  <div></div>
</template>

<script>

/* eslint-disable no-constant-condition */
// require('froala-editor/css/froala_style.css');
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/plugins/colors.min.css'
import 'froala-editor/css/plugins/char_counter.min.css'
import 'froala-editor/css/plugins/code_view.min.css'
import 'froala-editor/css/plugins/emoticons.min.css'
import 'froala-editor/css/plugins/file.min.css'
import 'froala-editor/css/plugins/fullscreen.min.css'
import 'froala-editor/css/plugins/help.min.css'
import 'froala-editor/css/plugins/image.min.css'
import 'froala-editor/css/plugins/line_breaker.min.css'
import 'froala-editor/css/plugins/quick_insert.min.css'
import 'froala-editor/css/plugins/special_characters.min.css'
import 'froala-editor/css/plugins/table.min.css'
import 'froala-editor/css/plugins/video.min.css'
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/char_counter.min.js'
import 'froala-editor/js/plugins/code_beautifier.min.js'
import 'froala-editor/js/plugins/code_view.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/emoticons.min.js'
import 'froala-editor/js/plugins/entities.min.js'
import 'froala-editor/js/plugins/file.min.js'
import 'froala-editor/js/plugins/font_family.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/fullscreen.min.js'
import 'froala-editor/js/plugins/help.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/inline_class.min.js'
import 'froala-editor/js/plugins/line_breaker.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/paragraph_format.min.js'
import 'froala-editor/js/plugins/paragraph_style.min.js'
import 'froala-editor/js/plugins/quick_insert.min.js'
import 'froala-editor/js/plugins/quote.min.js'
import 'froala-editor/js/plugins/save.min.js'
import 'froala-editor/js/plugins/special_characters.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/url.min.js'
import 'froala-editor/js/plugins/video.min.js'
import FroalaEditor from 'froala-editor'
import JwtService from "@/core/services/JwtService";

export default {
  name: "FullFroala",
  components: {},
  props: {
    modelValue: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      initEvents: [],

      // Tag on which the editor is initialized.
      currentTag: 'textarea',

      // Editor element.
      editor: null,


      config: {
        toolbarButtons: [
          'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|',
          'fontFamily', 'fontSize', 'color', 'inlineStyle', '|',
          'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '|',
          'insertLink', 'insertImage', 'insertVideo', 'embedly', 'insertTable', '|',
          'insertFile', 'insertHR', 'selectAll', 'clearFormatting', '|',
          'undo', 'redo', 'html',
        ],
        imageUploadURL: process.env.VUE_APP_API_URI + "/document/image/upload",
        requestHeaders: {
          Authorization: JwtService.getToken(),
        },
        "height": 500,
        attribution: false,
        "key": "iTB2xB2C4D5C2B1B1wd1DBKSPF1WKTUCQOa1OURPJ1KDe2F-11D2C2D2G2C3B3B1D6C1C1==",
        videoAllowedProviders: ['youtube', 'vimeo'],
        videoInsertButtons: ['videoBack', '|', 'videoByURL'],
        documentReady: true,
        // videoAllowedProviders: ['youtube', 'vimeo'],
        // videoInsertButtons: ['videoBack', '|', 'videoByURL']
      },
      // Current config.
      currentConfig: null,
      // Editor options config
      defaultConfig: {
        immediateVueModelUpdate: false,
        vueIgnoreAttrs: null
      },

      editorInitialized: false,

      SPECIAL_TAGS: ['img', 'button', 'input', 'a'],
      INNER_HTML_ATTR: 'innerHTML',
      hasSpecialTag: false,

      model: null,
      oldModel: null
    }
  },
  emits: ['update:modelValue'],
  watch: {
    modelValue: function () {
      this.model = this.modelValue;
      this.updateValue();
    }
  },
  created() {
    this.model = this.modelValue;
  },
  mounted() {
    if (this.SPECIAL_TAGS.indexOf(this.currentTag) !== -1) {
      this.hasSpecialTag = true;
    }
    this.createEditor();
  },

  methods: {
    updateValue: function () {
      if (JSON.stringify(this.oldModel) === JSON.stringify(this.model)) {
        return;
      }
      this.setContent();
    },

    createEditor: function () {
      if (this.editorInitialized) {
        return;
      }
      this.currentConfig = this.clone(this.config || this.defaultConfig);
      this.currentConfig = {...this.currentConfig};
      this.setContent(true);
      // Bind editor events.
      this.registerEvents();
      this.initListeners();
      this.editor = new FroalaEditor(this.$el, this.currentConfig);
      this.editorInitialized = true;
    },

    // Return clone object
    clone(item) {
      const me = this;
      if (!item) {
        return item;
      } // null, undefined values check

      let types = [Number, String, Boolean],
        result;

      // normalizing primitives if someone did new String('aaa'), or new Number('444');
      types.forEach(function (type) {
        if (item instanceof type) {
          result = type(item);
        }
      });

      if (typeof result == "undefined") {
        if (Object.prototype.toString.call(item) === "[object Array]") {
          result = [];
          item.forEach(function (child, index) {
            result[index] = me.clone(child);
          });
        } else if (typeof item == "object") {
          // testing that this is DOM
          if (item.nodeType && typeof item.cloneNode == "function") {
            result = item.cloneNode(true);
          } else if (!item.prototype) { // check that this is a literal
            if (item instanceof Date) {
              result = new Date(item);
            } else {
              // it is an object literal
              result = {};
              for (var i in item) {
                result[i] = me.clone(item[i]);
              }
            }
          } else {
            if (false && item.constructor) {
              result = new item.constructor();
            } else {
              result = item;
            }
          }
        } else {
          result = item;
        }
      }
      return result;
    },

    setContent: function (firstTime) {
      if (!this.editorInitialized && !firstTime) {
        return;
      }
      if (this.model || this.model === '') {
        this.oldModel = this.model;
        if (this.hasSpecialTag) {
          this.setSpecialTagContent();
        } else {
          this.setNormalTagContent(firstTime);
        }
      }
    },

    setNormalTagContent: function (firstTime) {
      const self = this;

      function htmlSet() {
        self.editor.html.set(self.model || '');
        //This will reset the undo stack everytime the model changes externally. Can we fix this?
        self.editor.undo.saveStep();
        self.editor.undo.reset();

      }

      if (firstTime) {
        this.registerEvent('initialized', function () {
          htmlSet();
        });
      } else {
        htmlSet();
      }
    },

    setSpecialTagContent: function () {

      const tags = this.model;

      // add tags on element
      if (tags) {

        for (var attr in tags) {
          if (Object.prototype.hasOwnProperty.call(tags, attr) && attr != this.INNER_HTML_ATTR) {
            this.$el.setAttribute(attr, tags[attr]);
          }
        }

        if (Object.prototype.hasOwnProperty.call(tags, this.INNER_HTML_ATTR)) {
          this.$el.innerHTML = tags[this.INNER_HTML_ATTR];
        }
      }
    },

    destroyEditor: function () {

      if (this.editor) {

        this.editor.destroy();
        this.editorInitialized = false;
        this.editor = null;
      }
    },

    getEditor: function () {
      return this.editor;
    },


    updateModel: function () {

      var modelContent = '';

      if (this.hasSpecialTag) {

        var attributeNodes = this.$el[0].attributes;
        var attrs = {};

        for (var i = 0; i < attributeNodes.length; i++) {

          var attrName = attributeNodes[i].name;
          if (this.currentConfig.vueIgnoreAttrs && this.currentConfig.vueIgnoreAttrs.indexOf(attrName) !== -1) {
            continue;
          }
          attrs[attrName] = attributeNodes[i].value;
        }

        if (this.$el[0].innerHTML) {
          attrs[this.INNER_HTML_ATTR] = this.$el[0].innerHTML;
        }

        modelContent = attrs;
      } else {

        var returnedHtml = this.editor.html.get();
        if (typeof returnedHtml === 'string') {
          modelContent = returnedHtml;
        }
      }

      this.oldModel = modelContent;
      this.$emit('update:modelValue', modelContent);
    },

    initListeners: function () {
      const self = this;

      this.registerEvent('initialized', function () {
        if (self.editor.events) {
          // bind contentChange and keyup event to froalaModel
          self.editor.events.on('contentChanged', function () {
            self.updateModel();
          });

          if (self.currentConfig.immediateVueModelUpdate) {
            self.editor.events.on('keyup', function () {
              self.updateModel();
            });
          }
        }
      })
      this.registerEvent('image.uploaded', function (response) {
        console.log(response);
        console.log(response);
      })
    },

    // register event on editor element
    registerEvent: function (eventName, callback) {

      if (!eventName || !callback) {
        return;
      }

      // Initialized event.
      if (eventName == 'initialized') {

        this.initEvents.push(callback);
      } else {
        if (!this.currentConfig.events) {
          this.currentConfig.events = {};
        }

        this.currentConfig.events[eventName] = callback;
      }

    },

    registerEvents: function () {

      // Handle initialized on its own.
      this.registerInitialized();

      // Get current events.
      const events = this.currentConfig.events;

      if (!events) {
        return;
      }

      for (let event in events) {
        if (Object.prototype.hasOwnProperty.call(events, event) && event != 'initialized') {
          this.registerEvent(event, events[event]);
        }
      }
    },

    registerInitialized: function () {
      // Bind initialized.
      if (!this.currentConfig.events) {
        this.currentConfig.events = {};
      }

      // Set original initialized event.
      if (this.currentConfig.events.initialized) {
        this.registerEvent('initialized', this.currentConfig.events.initialized);
      }

      // Bind initialized event.
      this.currentConfig.events.initialized = () => {
        for (var i = 0; i < this.initEvents.length; i++) {
          this.initEvents[i].call(this.editor);
        }
      }
    }
  }
}
</script>

<style>
p {
  margin-bottom: 0 !important;

}

.fr-box.fr-document .fr-wrapper .fr-element {
  width: unset;
}

.fr-box.fr-document .fr-wrapper {
  min-width: auto;
}
</style>
