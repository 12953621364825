import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61bb3336"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tk-panel"
}
const _hoisted_2 = { class: "tk-header d-flex align-items-center justify-content-between" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  href: "javascript:void(0)",
  class: "pull-right"
}
const _hoisted_6 = { class: "tk-body" }
const _hoisted_7 = {
  key: 1,
  class: "offcanvas-overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.show)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.customHeader)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "title", {}, undefined, true)
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.label), 1)),
            _createElementVNode("a", _hoisted_5, [
              _createElementVNode("i", {
                class: "fa fa-times",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.show)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7))
      : _createCommentVNode("", true)
  ], 64))
}