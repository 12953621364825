
import { defineComponent, ref } from "vue";
import { Form } from 'vee-validate';
import store from "@/store";

export default defineComponent({
  name: "BaseForm",
  components: {Form},
  props: {
    validate: {},
    submitting: {required: false, default: false, type: Boolean}
  },
  emits: ['form-discard', 'form-submit'],
  setup(_, {emit}) {
    const baseForm = ref<HTMLFormElement | null>(null);
    const formDiscard = () => {
      emit('form-discard')
    }
    const formSubmit = () => {
      emit('form-submit')
    }
    store.subscribe((mutation, state) => {
      if (mutation.type === 'setFormError') {
        baseForm.value?.setErrors(state.ValidationModule.errors)
      }
    });
    return {
      formSubmit,
      formDiscard,
      baseForm,
    }
  },
})
